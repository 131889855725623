@use "sass:math";

@function strip-unit($number) {
  @if type-of($number)=="number" and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }

  @return $number;
}


@function vw($window_width, $size) {
  @return math.div($size, $window_width) * 100vw;
}

@function percent($n, $d) {
  @return calc($n / $d * 100%)
}

$inner: 1100px;
$container: 1380px;

// ※キャンパス幅ではなくインナーの幅を指す

// $pc-padding: 25;
// 余白
$padding-pc: 25px;
$padding-sp: 19px;
$side-space: 20;
// ※PC時：デザインカンプの余白の幅ではない。PC時は通常25pxで大丈夫！

////////////////////
// フォント設定
////////////////////

$base-font-family: "";
$second-font-family: "";
// ※検証ツールでしっかり反映されているか確認する

body {
  // font-family: $base-font-family;
}

// フォントウェイト
$regular: 300;
$medium: 400;
$semiBold: 600;
$bold: 700;

////////////////////
// 色の指定
////////////////////

//色指定（共通性のあるものは出来る限り指定すると良い）
$black: #111;
$white: #fff;

////////////////////
// メディアクエリとブレークポイントの設定
////////////////////

//ブレイクポイント
$breakpoints: (
  sm: 500,
  md: 768,
  lg: 834,
  xl: 1440,
);

//メディアクエリー
$mediaquerys: (
  sm: "screen and (min-width: #{map-get($breakpoints,'sm')}px)",
  md: "screen and (min-width: #{map-get($breakpoints,'md')}px)",
  lg: "screen and (min-width: #{map-get($breakpoints,'lg')}px)",
  xl: "screen and (min-width: #{map-get($breakpoints,'xl')}px)",
  tab: "screen and (min-width: 768px) and (max-width:833px)",
);

// @include mq(){}で書くとブレークポイントが反映される（初期値はmd）
@mixin mq($mediaquery: md) {
  @if type-of($mediaquery)=="number" {
    @media screen and (min-width: ($mediaquery + px)) {
      @content;
    }
  }

  @else {
    @media #{map-get($mediaquerys, $mediaquery)} {
      @content;
    }
  }
}

// remへの計算式（16pxを基準としている。10pxを基準とする場合は16を10に変更する）
@function rem($pixels) {
  @return math.div($pixels, 16) * 1rem;
}

@mixin fz($pixels) {
  font-size: rem($pixels);

  // tab
  @include mq("md") {

    // font-sizeが13px以上だった場合
    @if ($pixels >=16) {
      // font-size: max(rem(15), 13px);
      font-size: max(rem($pixels), $pixels * 0.875px); //最小14px (最低-2px)
      // font-size: max(rem($pixels), 13.5px); //-2px
    }

    @else if ($pixels >=15) {
      // font-size: max(rem(15), 13px);
      // font-size: max(rem($pixels), $pixels * 0.9px);
      font-size: max(rem($pixels), 13.5px); //-1.5px
    }

    @else if($pixels >=14) {
      font-size: max(rem($pixels), 13px); //-1px
    }

    @else if($pixels >=13) {
      font-size: max(rem($pixels), 12.5px); //-0.5px
    }

    // font-sizeが13px未満だった場合
    @else {
      font-size: $pixels + px;
    }
  }
}

@function fz($pixels) {

  // font-sizeが13px以上だった場合
  @if ($pixels >=14) {
    // font-size: max(rem(15), 13px);
    @return max(rem($pixels), 13px);
  }

  @else {
    @return $pixels +px;
  }

  @return math.div($pixels, 16) * 1rem;
}

////////////////////
// 非表示設定
////////////////////

.add-section {

  //768px以上を表示
  .u-desktop {
    display: none;

    @include mq("md") {
      display: block;
    }
  }

  //モバイルのみ表示
  .u-mobile {
    @include mq("md") {
      display: none;
    }
  }

  .body--no-scroll {
    overflow: hidden;
  }
}

@mixin wrapper($width) {
  max-width: $width * 1px + $padding-pc * 2;
  width: 100%;
  padding-right: $padding-pc;
  padding-left: $padding-pc;
  margin-right: auto;
  margin-left: auto;
}

@mixin lh($value, $height) {
  line-height: calc(#{$height} / #{$value});
}

@mixin rect($value) {
  width: rem($value);
  height: rem($value);
}

@mixin mq_with-bp($bp) {
  @media screen and (max-width: ($bp + px)) {
    @content;
  }
}

@mixin min-mq_with-bp($bp) {
  @media screen and (min-width: ($bp + px)) {
    @content;
  }
}

@mixin padding-side($value) {
  padding-right: rem($value);
  padding-left: rem($value);
}

@mixin padding-vertical($value) {
  padding-top: rem($value);
  padding-bottom: rem($value);
}

@mixin margin-side($value) {
  margin-right: rem($value);
  margin-left: rem($value);
}

@mixin inner($value) {
  // @include mq("md") {
  max-width: rem($value);
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  // }
}

@mixin sp-inner($value) {
  max-width: rem($value);
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @include mq("md") {
    max-width: 100%;
    width: auto;
    margin-right: 0;
    margin-left: 0;
  }
}

@mixin inner-pc($value) {
  @include mq("md") {
    max-width: rem($value);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

@mixin tab-space($pad1: 50, $pad2: 80) {
  @media screen and (min-width: 500px) and (max-width: 600px) {
    // @content;
    @include padding-side($pad1);
  }

  @media screen and (min-width: 601px) and (max-width: 767px) {
    // @content;
    @include padding-side($pad2);
  }

  // @include min-mq_with-bp(500) {

  // }
}

/*------------------------------------------------------------
  layout-parts
------------------------------------------------------------*/

$layout: (
  container: 1380,
  outer: 1246,
  inner: 380,
);

$device: (
  pc: 1366,
  sp: 428
);

$sp-space: 24;
$pc-space: 50;

@function percentage_onInner($value) {
  $cv: calc(#{$value} / #{map-get($layout, inner)} * 100%);
  @return $cv;
}

@function percentage_onSP($value) {
  $cv: calc(#{$value} / #{map-get($device, sp)} * 100vw);
  @return $cv;
}

@function percentage_onPC($value) {
  $cv: calc(#{$value} / #{map-get($device, pc)} * 100vw);
  @return $cv;
}

@function aspect($width, $height) {
  @return calc($height / $width * 100%);
}


@function Percent($m_value, $d_value) {
  $cv: calc($m_value / $d_value * 100%);
  @return $cv;
}

@mixin hover() {
  &:hover {
    @media (hover: hover) {
      @content;
    }
  }

  &:active {
    @media (hover: none) {
      @content;
    }
  }
}

$sp_width: 430;

@mixin maintain_value_to_sp($value,$property) {
  #{$property}: vw($sp_width, $value);

  @include mq($sp_width) {
    #{$property}: calc($value / $sp_width * 100vw);
  }
}

@mixin vw($value, $property) {
  @media (max-width: ($sp_width * 1px)) {
    #{$property}: vw($sp_width, $value);
  }

  #{$property}: $value +px;

  @if ($property ==font-size) {
    @include mq("md") {
      @if ($value >=17) {
        #{$property}: max(vw(strip-unit($inner), $value), $value * 0.875px); //-1.5px
      }

      @if ($value >=11) {
        #{$property}: max(vw(strip-unit($inner), $value), $value * (0.875px + 0.015px * (16 - $value))); //-1.5px
      }

      @else {
        #{$property}: $value + px; // font-sizeが10pxだった時
      }
    }
  }

  @else {
    @include mq("md") {
      #{$property}: vw(strip-unit($inner), $value);
    }
  }

  @media (min-width: $inner) {
    #{$property}: $value +px;
  }
}

@mixin vw_important($value, $property) {
  @media (max-width: ($sp_width * 1px)) {
    #{$property}: vw($sp_width, $value) !important;
  }

  #{$property}: $value +px !important;

  @if ($property ==font-size) {
    @include mq("md") {
      @if ($value >=17) {
        #{$property}: max(vw(strip-unit($inner), $value), $value * 0.875px) !important; //-1.5px
      }

      @if ($value >=11) {
        #{$property}: max(vw(strip-unit($inner), $value), $value * (0.875px + 0.015px * (16 - $value))) !important; //-1.5px
      }

      @else {
        #{$property}: $value + px !important; // font-sizeが10pxだった時
      }
    }
  }

  @else {
    @include mq("md") {
      #{$property}: vw(strip-unit($inner), $value) !important;
    }
  }

  @media (min-width: $inner) {
    #{$property}: $value +px !important;
  }
}
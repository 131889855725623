/* リキッドレイアウト対応 */
@use "global" as *;

.add-section {

  /* pcの電話番号発信対応 */
  a[href^="tel:"] {
    @include mq("md") {
      pointer-events: none;
    }
  }

  /* ホバー */
  a {
    text-decoration: none;
    color: inherit;
    transition: opacity 0.3s;
  }

  img {
    width: 100%;
    vertical-align: bottom;
  }
}

html {

  //~375px
  @media (max-width: 414px) {
    font-size: vw(414, 16);
  }

  //375px~787px
  font-size: 16px;

  @include mq("md") {
    font-size: vw(strip-unit($inner), 16);
  }

  // @include min-mq_with-bp(834) {
  //     font-size: vw(strip-unit($inner), 16);
  // }

  //inner~max-screen
  @media (min-width: $inner) {
    font-size: 16px;
  }
}
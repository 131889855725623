@use "global" as *;

.top-interview {
  font-family: "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ";
  @include vw(20, padding-top);
  @include vw(75, margin-bottom);

  @include mq(500) {
    @include vw(40, margin-bottom);
  }

  @include mq("md") {
    @include vw(25, padding-top);
  }
}

.top-interview .interview-items {
  grid-template-columns: repeat(1, 1fr);

  @include mq(1000) {
    max-width: 500px;
  }
}

.top-interview .media__contents-info {
  letter-spacing: 0.01em;

  @include mq(430) {
    letter-spacing: 0.05em;
  }
}
.add-section,
.add-section::before,
.add-section::after {
  box-sizing: border-box;
  margin: 0;
}

.add-section {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding */
  ul,
  ol {
    padding: 0;
  }

  /* Remove default margin */
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol,
  figure,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Remove list styles on ul, ol elements with a class attribute */
  ul,
  ol {
    list-style: none;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
    width: 100%;
  }

  /* Natural flow and rhythm in articles by default */
  article>*+* {
    margin-top: 1em;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Blur images when they have no alt attribute */
  img:not([alt]) {
    filter: blur(10px);
  }


  /* フォームリセット */
  input,
  button,
  select,
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
  }

  textarea {
    resize: vertical;
  }

  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  input[type="submit"],
  input[type="button"],
  label,
  button,
  select {
    cursor: pointer;
  }

  select::-ms-expand {
    display: none;
  }

  table {
    display: block;
    width: 100%;
    border-collapse: collapse;
  }

  thead {
    display: block;
  }

  tbody {
    display: block;
    width: 100%;
  }

  tr {
    display: block;
  }

  th,
  td {
    width: 100%;
    height: 100%;
  }

  th {
    display: block;
  }

  td {
    display: block;
  }
}
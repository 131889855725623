@use "global" as *;

.interview {
  font-family: "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ";
  background: url("../img/common/bg_mode_wood.jpg");
  background-repeat: repeat;
  @include vw(45, padding-top);
  @include vw(73, padding-bottom);
  @include vw(12, padding-right);
  @include vw(12, padding-left);

  @include mq(500) {
    @include vw(25, padding-right);
    @include vw(25, padding-left);
  }

  @include mq("md") {
    padding-top: calc(54 / 768 * 100vw);
  }

  @include mq(1100) {
    @include vw(97, padding-top);
    @include vw(84, padding-bottom);
  }
}

.interview-items {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: rem(50);
  @include vw(370, max-width);
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @include mq(500) {
    max-width: 440px;
    @include vw(30, gap);
  }

  @include mq(1000) {
    @include vw(1030, max-width);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    grid-template-columns: repeat(2, 1fr);
  }
}

.interview .interview__title {
  @include vw(20, font-size);
  font-weight: 600;
  position: relative;
  text-align: center;
  @include vw(30, margin-bottom);
  letter-spacing: 0;

  @include mq(500) {
    @include vw(40, margin-bottom);
  }

  @include mq("md") {
    margin-bottom: calc(53 / 768 * 100vw);
    font-size: 20px;
  }

  @include mq(1000) {
    @include vw(24, font-size);
    @include vw(84, margin-bottom);
  }

  @include mq(1100) {
    // @include fz(28);
    font-size: 28px;
  }
}

.interview__title::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include vw(123, width);
  aspect-ratio: 214 / 132;
  background-image: url("../img/common/bg_maru.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;

  @include mq("md") {
    width: 123px;
  }

  @include mq(1000) {
    @include vw(214, width);
  }
}
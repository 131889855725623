@use "global" as *;

.media {
  position: relative;
}

.media--recruit {
  @include mq(500) {
    position: static;
    display: flex;
  }
}

.media--single {
  // @include vw(48, margin-bottom);
  @include maintain_value_to_sp(48, margin-bottom);

  @include mq(480) {
    position: relative;
    // @include vw(92, margin-bottom);
    margin-bottom: 92px;
  }
}

.media--single .media__contents {
  color: #fff;

  @include mq(480) {
    // @include vw(-53, bottom);
    bottom: -53px;
    top: auto;
    height: auto;

    // @include vw(35, padding-bottom);
    padding-bottom: 35px;
    // @include vw(30, padding-top);

    // @include vw(48, padding-top);
    padding-top: 48px;
    width: 455.45px;
  }

  @include mq(1001) {
    max-width: 501px;
    width: calc(501 / 1100 * 100vw);
  }
}

.media--single .media__img {
  @include mq(480) {
    width: 527.27px;
  }

  @include mq(1001) {
    max-width: 580px;
    width: calc(580 / 1100 * 100vw);
  }
}

.media--recruit .media__contents {
  @include mq(500) {
    width: 50%;
    position: static;
  }

  @include mq("md") {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}


.media__contents {
  position: absolute;
  right: 0;
  @include vw(30, top);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  @include vw(16, padding-top);
  @include vw(14, padding-bottom);
  width: calc(225 / 369 * 100%);

  @include mq(500) {
    height: auto;
    @include vw(20, padding-top);
    @include vw(20, padding-bottom);
  }

  @include mq(1100) {
    @include vw(30, padding-top);
    @include vw(24, padding-bottom);
  }
}

.media__img {
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: calc(200 / 369 * 100%);

  @include mq(500) {
    width: 50%;
  }
}

.media--recruit .media__img {
  display: block;

  @include hover() {
    img {
      transform: scale(1.1);
    }
  }
}

.media--recruit .media__img img {
  transition: all 0.35s;
}

.media .media__title {
  @include vw(16, font-size);
  font-weight: 600;
  text-align: left;
  line-height: 1.48;
  white-space: pre-line;
  @include vw(10, margin-bottom);
  @include vw(23, padding-right);
  @include vw(16, padding-left);

  @include mq("md") {
    // font-size: max(rem(18), 16px);
    // @include vw(18, font-size);
    font-size: max(calc(18 / 1100 * 100vw), 16px);
    @include vw(20, padding-left);
  }

  @include mq(1100) {
    @include vw(18, font-size);
    @include vw(31, padding-left);
  }
}

.media--single .media__title {
  @include mq(500) {
    @include vw(20, margin-bottom);
  }

  @include mq(480) {
    // @include vw(22, font-size);
    letter-spacing: 1.1px;
    padding-left: 43px;
    margin-bottom: 35px;
  }

  @include mq("md") {
    // @include vw(1.1, letter-spacing);
    // @include vw(43, padding-left);
    // @include vw(35, margin-bottom);
  }
}

.media--recruit .media__title {
  @include mq("md") {
    padding-left: 20px;
  }
}

.media__contents-footer {
  text-align: right;
  @include vw(10, padding-left);
  @include vw(11, padding-right);
  white-space: nowrap;

  @include mq("md") {
    @include vw(19, padding-right);
  }

  @include mq(1000) {
    @include vw(15, padding-left);
    @include vw(23, padding-right);
  }
}

.media--single .media__contents-footer {
  @include mq(480) {
    // @include vw(25, padding-right);
    padding-right: 39px;
  }

  @include mq("md") {
    // @include vw(39, padding-right);
  }
}

.media--recruit .media__contents-footer {
  @include mq(500) {
    @include vw(14, padding-right);
  }

  @include mq("md") {
    padding-right: 20px;
  }
}

.media__contents-yomigana,
.media__contents-info {
  @include vw(12, font-size);
  font-weight: 300;
  @include vw(1.6, letter-spacing);
  line-height: 1;
}

.media--single .media__contents-yomigana,
.media--single .media__contents-info {
  @include mq(480) {
    // @include vw(14, font-size);
    font-size: 14px;
    // @include vw(1.8, letter-spacing);
    letter-spacing: 1.8px;
  }
}

.media--recruit .media__contents-yomigana,
.media--recruit .media__contents-info {
  @include mq("md") {
    letter-spacing: 1.6px
  }
}

.media .media__contents-yomigana {
  @include vw(4.2, margin-bottom);

  @include mq("md") {
    @include vw(6.2, margin-bottom);
  }

  @include mq(1100) {
    @include vw(4.2, margin-bottom);
  }
}

.media--single .media__contents-yomigana {
  @include mq("md") {
    @include vw(5.2, margin-bottom);
  }
}

.media .media__contents-name {
  @include vw(16, font-size);
  font-weight: 600;
  @include vw(1.2, letter-spacing);
  line-height: 1;
  @include vw(4, margin-bottom);

  @include mq(1100) {
    @include vw(20, font-size);
  }
}

.media--single .media__contents-name {
  @include mq(480) {
    // @include vw(5, margin-bottom);
    margin-bottom: 5px;
  }
}

.media--recruit .media__contents-name {
  @include mq("md") {
    // font-size: max(rem(16), 16px);
    font-size: 16px;
  }
}

.media__contents-info {
  // @include vw(0.3, letter-spacing);
  letter-spacing: 0.05em;

  // @include mq(450) {
  //   @include vw(1, letter-spacing);
  // }

  // @include mq("md") {
  //   @include vw(0.5, letter-spacing);
  // }

  // @include mq(1100) {
  //   @include vw(1, letter-spacing);
  // }
}

.media--single .media__contents-info {
  @include mq(480) {
    // @include vw(1.2, letter-spacing);
    letter-spacing: 1.2px;
  }
}

.media--recruit .media__contents-info {
  @include mq(500) {
    @include vw(0.65, letter-spacing);
  }

  @include mq("md") {
    @include vw(0.3, letter-spacing);
  }

  @include mq(1100) {
    @include vw(1.2, letter-spacing);
  }
}

/* 投稿を4枚ごとに背景色を変える */
.media--recruit:nth-child(4n+1) .media__contents {
  background-color: #C03605;
  /* 背景色を指定 */
}

.media--recruit:nth-child(4n+2) .media__contents {
  background-color: #364961;
  /* 背景色を指定 */
}

.media--recruit:nth-child(4n+3) .media__contents {
  background-color: #3D6155;
  /* 背景色を指定 */
}

.media--recruit:nth-child(4n+4) .media__contents {
  background-color: #A07C28;
  /* 背景色を指定 */
}
@charset "UTF-8";
.add-section .u-desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .add-section .u-desktop {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .add-section .u-mobile {
    display: none;
  }
}
.add-section .body--no-scroll {
  overflow: hidden;
}

/*------------------------------------------------------------
  layout-parts
------------------------------------------------------------*/
/* リキッドレイアウト対応 */
.add-section {
  /* pcの電話番号発信対応 */
  /* ホバー */
}
@media screen and (min-width: 768px) {
  .add-section a[href^="tel:"] {
    pointer-events: none;
  }
}
.add-section a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
}
.add-section img {
  width: 100%;
  vertical-align: bottom;
}

html {
  font-size: 16px;
}
@media (max-width: 414px) {
  html {
    font-size: 3.8647342995vw;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 1.4545454545vw;
  }
}
@media (min-width: 1100px) {
  html {
    font-size: 16px;
  }
}

.add-section,
.add-section::before,
.add-section::after {
  box-sizing: border-box;
  margin: 0;
}

.add-section {
  /* Remove default padding */
  /* Remove default margin */
  /* Remove list styles on ul, ol elements with a class attribute */
  /* A elements that don't have a class get default styles */
  /* Make images easier to work with */
  /* Natural flow and rhythm in articles by default */
  /* Inherit fonts for inputs and buttons */
  /* Blur images when they have no alt attribute */
  /* フォームリセット */
}
.add-section *,
.add-section *::before,
.add-section *::after {
  box-sizing: border-box;
}
.add-section ul,
.add-section ol {
  padding: 0;
}
.add-section h1,
.add-section h2,
.add-section h3,
.add-section h4,
.add-section p,
.add-section ul,
.add-section ol,
.add-section figure,
.add-section blockquote,
.add-section dl,
.add-section dd {
  margin: 0;
}
.add-section ul,
.add-section ol {
  list-style: none;
}
.add-section a:not([class]) {
  text-decoration-skip-ink: auto;
}
.add-section img {
  max-width: 100%;
  display: block;
  width: 100%;
}
.add-section article > * + * {
  margin-top: 1em;
}
.add-section input,
.add-section button,
.add-section textarea,
.add-section select {
  font: inherit;
}
.add-section img:not([alt]) {
  filter: blur(10px);
}
.add-section input,
.add-section button,
.add-section select,
.add-section textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}
.add-section textarea {
  resize: vertical;
}
.add-section input[type=checkbox],
.add-section input[type=radio] {
  display: none;
}
.add-section input[type=submit],
.add-section input[type=button],
.add-section label,
.add-section button,
.add-section select {
  cursor: pointer;
}
.add-section select::-ms-expand {
  display: none;
}
.add-section table {
  display: block;
  width: 100%;
  border-collapse: collapse;
}
.add-section thead {
  display: block;
}
.add-section tbody {
  display: block;
  width: 100%;
}
.add-section tr {
  display: block;
}
.add-section th,
.add-section td {
  width: 100%;
  height: 100%;
}
.add-section th {
  display: block;
}
.add-section td {
  display: block;
}

.media {
  position: relative;
}

@media screen and (min-width: 500px) {
  .media--recruit {
    position: static;
    display: flex;
  }
}

.media--single {
  margin-bottom: 11.1627906977vw;
}
@media screen and (min-width: 430px) {
  .media--single {
    margin-bottom: 11.1627906977vw;
  }
}
@media screen and (min-width: 480px) {
  .media--single {
    position: relative;
    margin-bottom: 92px;
  }
}

.media--single .media__contents {
  color: #fff;
}
@media screen and (min-width: 480px) {
  .media--single .media__contents {
    bottom: -53px;
    top: auto;
    height: auto;
    padding-bottom: 35px;
    padding-top: 48px;
    width: 455.45px;
  }
}
@media screen and (min-width: 1001px) {
  .media--single .media__contents {
    max-width: 501px;
    width: 45.5454545455vw;
  }
}

@media screen and (min-width: 480px) {
  .media--single .media__img {
    width: 527.27px;
  }
}
@media screen and (min-width: 1001px) {
  .media--single .media__img {
    max-width: 580px;
    width: 52.7272727273vw;
  }
}

@media screen and (min-width: 500px) {
  .media--recruit .media__contents {
    width: 50%;
    position: static;
  }
}
@media screen and (min-width: 768px) {
  .media--recruit .media__contents {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.media__contents {
  position: absolute;
  right: 0;
  top: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding-top: 16px;
  padding-bottom: 14px;
  width: 60.9756097561%;
}
@media (max-width: 430px) {
  .media__contents {
    top: 6.976744186vw;
  }
}
@media screen and (min-width: 768px) {
  .media__contents {
    top: 2.7272727273vw;
  }
}
@media (min-width: 1100px) {
  .media__contents {
    top: 30px;
  }
}
@media (max-width: 430px) {
  .media__contents {
    padding-top: 3.7209302326vw;
  }
}
@media screen and (min-width: 768px) {
  .media__contents {
    padding-top: 1.4545454545vw;
  }
}
@media (min-width: 1100px) {
  .media__contents {
    padding-top: 16px;
  }
}
@media (max-width: 430px) {
  .media__contents {
    padding-bottom: 3.2558139535vw;
  }
}
@media screen and (min-width: 768px) {
  .media__contents {
    padding-bottom: 1.2727272727vw;
  }
}
@media (min-width: 1100px) {
  .media__contents {
    padding-bottom: 14px;
  }
}
@media screen and (min-width: 500px) {
  .media__contents {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 500px) and (max-width: 430px) {
  .media__contents {
    padding-top: 4.6511627907vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 768px) {
  .media__contents {
    padding-top: 1.8181818182vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 1100px) {
  .media__contents {
    padding-top: 20px;
  }
}
@media screen and (min-width: 500px) and (max-width: 430px) {
  .media__contents {
    padding-bottom: 4.6511627907vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 768px) {
  .media__contents {
    padding-bottom: 1.8181818182vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 1100px) {
  .media__contents {
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 1100px) {
  .media__contents {
    padding-top: 30px;
    padding-bottom: 24px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 430px) {
  .media__contents {
    padding-top: 6.976744186vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 768px) {
  .media__contents {
    padding-top: 2.7272727273vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 1100px) {
  .media__contents {
    padding-top: 30px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 430px) {
  .media__contents {
    padding-bottom: 5.5813953488vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 768px) {
  .media__contents {
    padding-bottom: 2.1818181818vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 1100px) {
  .media__contents {
    padding-bottom: 24px;
  }
}

.media__img {
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: 54.2005420054%;
}
@media screen and (min-width: 500px) {
  .media__img {
    width: 50%;
  }
}

.media--recruit .media__img {
  display: block;
}
@media (hover: hover) {
  .media--recruit .media__img:hover img {
    transform: scale(1.1);
  }
}
@media (hover: none) {
  .media--recruit .media__img:active img {
    transform: scale(1.1);
  }
}

.media--recruit .media__img img {
  transition: all 0.35s;
}

.media .media__title {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  line-height: 1.48;
  white-space: pre-line;
  margin-bottom: 10px;
  padding-right: 23px;
  padding-left: 16px;
}
@media (max-width: 430px) {
  .media .media__title {
    font-size: 3.7209302326vw;
  }
}
@media screen and (min-width: 768px) {
  .media .media__title {
    font-size: max(1.4545454545vw, 14px);
  }
}
@media (min-width: 1100px) {
  .media .media__title {
    font-size: 16px;
  }
}
@media (max-width: 430px) {
  .media .media__title {
    margin-bottom: 2.3255813953vw;
  }
}
@media screen and (min-width: 768px) {
  .media .media__title {
    margin-bottom: 0.9090909091vw;
  }
}
@media (min-width: 1100px) {
  .media .media__title {
    margin-bottom: 10px;
  }
}
@media (max-width: 430px) {
  .media .media__title {
    padding-right: 5.3488372093vw;
  }
}
@media screen and (min-width: 768px) {
  .media .media__title {
    padding-right: 2.0909090909vw;
  }
}
@media (min-width: 1100px) {
  .media .media__title {
    padding-right: 23px;
  }
}
@media (max-width: 430px) {
  .media .media__title {
    padding-left: 3.7209302326vw;
  }
}
@media screen and (min-width: 768px) {
  .media .media__title {
    padding-left: 1.4545454545vw;
  }
}
@media (min-width: 1100px) {
  .media .media__title {
    padding-left: 16px;
  }
}
@media screen and (min-width: 768px) {
  .media .media__title {
    font-size: max(1.6363636364vw, 16px);
    padding-left: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .media .media__title {
    padding-left: 4.6511627907vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .media .media__title {
    padding-left: 1.8181818182vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .media .media__title {
    padding-left: 20px;
  }
}
@media screen and (min-width: 1100px) {
  .media .media__title {
    font-size: 18px;
    padding-left: 31px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 430px) {
  .media .media__title {
    font-size: 4.1860465116vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 768px) {
  .media .media__title {
    font-size: max(1.6363636364vw, 15.75px);
    font-size: max(1.6363636364vw, 15.21px);
  }
}
@media screen and (min-width: 1100px) and (min-width: 1100px) {
  .media .media__title {
    font-size: 18px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 430px) {
  .media .media__title {
    padding-left: 7.2093023256vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 768px) {
  .media .media__title {
    padding-left: 2.8181818182vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 1100px) {
  .media .media__title {
    padding-left: 31px;
  }
}

@media screen and (min-width: 500px) {
  .media--single .media__title {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 500px) and (max-width: 430px) {
  .media--single .media__title {
    margin-bottom: 4.6511627907vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 768px) {
  .media--single .media__title {
    margin-bottom: 1.8181818182vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 1100px) {
  .media--single .media__title {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 480px) {
  .media--single .media__title {
    letter-spacing: 1.1px;
    padding-left: 43px;
    margin-bottom: 35px;
  }
}
@media screen and (min-width: 768px) {
  .media--recruit .media__title {
    padding-left: 20px;
  }
}

.media__contents-footer {
  text-align: right;
  padding-left: 10px;
  padding-right: 11px;
  white-space: nowrap;
}
@media (max-width: 430px) {
  .media__contents-footer {
    padding-left: 2.3255813953vw;
  }
}
@media screen and (min-width: 768px) {
  .media__contents-footer {
    padding-left: 0.9090909091vw;
  }
}
@media (min-width: 1100px) {
  .media__contents-footer {
    padding-left: 10px;
  }
}
@media (max-width: 430px) {
  .media__contents-footer {
    padding-right: 2.5581395349vw;
  }
}
@media screen and (min-width: 768px) {
  .media__contents-footer {
    padding-right: 1vw;
  }
}
@media (min-width: 1100px) {
  .media__contents-footer {
    padding-right: 11px;
  }
}
@media screen and (min-width: 768px) {
  .media__contents-footer {
    padding-right: 19px;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .media__contents-footer {
    padding-right: 4.4186046512vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .media__contents-footer {
    padding-right: 1.7272727273vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .media__contents-footer {
    padding-right: 19px;
  }
}
@media screen and (min-width: 1000px) {
  .media__contents-footer {
    padding-left: 15px;
    padding-right: 23px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 430px) {
  .media__contents-footer {
    padding-left: 3.488372093vw;
  }
}
@media screen and (min-width: 1000px) and (min-width: 768px) {
  .media__contents-footer {
    padding-left: 1.3636363636vw;
  }
}
@media screen and (min-width: 1000px) and (min-width: 1100px) {
  .media__contents-footer {
    padding-left: 15px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 430px) {
  .media__contents-footer {
    padding-right: 5.3488372093vw;
  }
}
@media screen and (min-width: 1000px) and (min-width: 768px) {
  .media__contents-footer {
    padding-right: 2.0909090909vw;
  }
}
@media screen and (min-width: 1000px) and (min-width: 1100px) {
  .media__contents-footer {
    padding-right: 23px;
  }
}

@media screen and (min-width: 480px) {
  .media--single .media__contents-footer {
    padding-right: 39px;
  }
}
@media screen and (min-width: 500px) {
  .media--recruit .media__contents-footer {
    padding-right: 14px;
  }
}
@media screen and (min-width: 500px) and (max-width: 430px) {
  .media--recruit .media__contents-footer {
    padding-right: 3.2558139535vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 768px) {
  .media--recruit .media__contents-footer {
    padding-right: 1.2727272727vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 1100px) {
  .media--recruit .media__contents-footer {
    padding-right: 14px;
  }
}
@media screen and (min-width: 768px) {
  .media--recruit .media__contents-footer {
    padding-right: 20px;
  }
}

.media__contents-yomigana,
.media__contents-info {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.6px;
  line-height: 1;
}
@media (max-width: 430px) {
  .media__contents-yomigana,
.media__contents-info {
    font-size: 2.7906976744vw;
  }
}
@media screen and (min-width: 768px) {
  .media__contents-yomigana,
.media__contents-info {
    font-size: max(1.0909090909vw, 11.22px);
  }
}
@media (min-width: 1100px) {
  .media__contents-yomigana,
.media__contents-info {
    font-size: 12px;
  }
}
@media (max-width: 430px) {
  .media__contents-yomigana,
.media__contents-info {
    letter-spacing: 0.3720930233vw;
  }
}
@media screen and (min-width: 768px) {
  .media__contents-yomigana,
.media__contents-info {
    letter-spacing: 0.1454545455vw;
  }
}
@media (min-width: 1100px) {
  .media__contents-yomigana,
.media__contents-info {
    letter-spacing: 1.6px;
  }
}

@media screen and (min-width: 480px) {
  .media--single .media__contents-yomigana,
.media--single .media__contents-info {
    font-size: 14px;
    letter-spacing: 1.8px;
  }
}

@media screen and (min-width: 768px) {
  .media--recruit .media__contents-yomigana,
.media--recruit .media__contents-info {
    letter-spacing: 1.6px;
  }
}

.media .media__contents-yomigana {
  margin-bottom: 4.2px;
}
@media (max-width: 430px) {
  .media .media__contents-yomigana {
    margin-bottom: 0.976744186vw;
  }
}
@media screen and (min-width: 768px) {
  .media .media__contents-yomigana {
    margin-bottom: 0.3818181818vw;
  }
}
@media (min-width: 1100px) {
  .media .media__contents-yomigana {
    margin-bottom: 4.2px;
  }
}
@media screen and (min-width: 768px) {
  .media .media__contents-yomigana {
    margin-bottom: 6.2px;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .media .media__contents-yomigana {
    margin-bottom: 1.4418604651vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .media .media__contents-yomigana {
    margin-bottom: 0.5636363636vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .media .media__contents-yomigana {
    margin-bottom: 6.2px;
  }
}
@media screen and (min-width: 1100px) {
  .media .media__contents-yomigana {
    margin-bottom: 4.2px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 430px) {
  .media .media__contents-yomigana {
    margin-bottom: 0.976744186vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 768px) {
  .media .media__contents-yomigana {
    margin-bottom: 0.3818181818vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 1100px) {
  .media .media__contents-yomigana {
    margin-bottom: 4.2px;
  }
}

@media screen and (min-width: 768px) {
  .media--single .media__contents-yomigana {
    margin-bottom: 5.2px;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .media--single .media__contents-yomigana {
    margin-bottom: 1.2093023256vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .media--single .media__contents-yomigana {
    margin-bottom: 0.4727272727vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .media--single .media__contents-yomigana {
    margin-bottom: 5.2px;
  }
}

.media .media__contents-name {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.2px;
  line-height: 1;
  margin-bottom: 4px;
}
@media (max-width: 430px) {
  .media .media__contents-name {
    font-size: 3.7209302326vw;
  }
}
@media screen and (min-width: 768px) {
  .media .media__contents-name {
    font-size: max(1.4545454545vw, 14px);
  }
}
@media (min-width: 1100px) {
  .media .media__contents-name {
    font-size: 16px;
  }
}
@media (max-width: 430px) {
  .media .media__contents-name {
    letter-spacing: 0.2790697674vw;
  }
}
@media screen and (min-width: 768px) {
  .media .media__contents-name {
    letter-spacing: 0.1090909091vw;
  }
}
@media (min-width: 1100px) {
  .media .media__contents-name {
    letter-spacing: 1.2px;
  }
}
@media (max-width: 430px) {
  .media .media__contents-name {
    margin-bottom: 0.9302325581vw;
  }
}
@media screen and (min-width: 768px) {
  .media .media__contents-name {
    margin-bottom: 0.3636363636vw;
  }
}
@media (min-width: 1100px) {
  .media .media__contents-name {
    margin-bottom: 4px;
  }
}
@media screen and (min-width: 1100px) {
  .media .media__contents-name {
    font-size: 20px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 430px) {
  .media .media__contents-name {
    font-size: 4.6511627907vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 768px) {
  .media .media__contents-name {
    font-size: max(1.8181818182vw, 17.5px);
    font-size: max(1.8181818182vw, 16.3px);
  }
}
@media screen and (min-width: 1100px) and (min-width: 1100px) {
  .media .media__contents-name {
    font-size: 20px;
  }
}

@media screen and (min-width: 480px) {
  .media--single .media__contents-name {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 768px) {
  .media--recruit .media__contents-name {
    font-size: 16px;
  }
}

.media__contents-info {
  letter-spacing: 0.05em;
}

@media screen and (min-width: 480px) {
  .media--single .media__contents-info {
    letter-spacing: 1.2px;
  }
}

@media screen and (min-width: 500px) {
  .media--recruit .media__contents-info {
    letter-spacing: 0.65px;
  }
}
@media screen and (min-width: 500px) and (max-width: 430px) {
  .media--recruit .media__contents-info {
    letter-spacing: 0.1511627907vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 768px) {
  .media--recruit .media__contents-info {
    letter-spacing: 0.0590909091vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 1100px) {
  .media--recruit .media__contents-info {
    letter-spacing: 0.65px;
  }
}
@media screen and (min-width: 768px) {
  .media--recruit .media__contents-info {
    letter-spacing: 0.3px;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .media--recruit .media__contents-info {
    letter-spacing: 0.0697674419vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .media--recruit .media__contents-info {
    letter-spacing: 0.0272727273vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .media--recruit .media__contents-info {
    letter-spacing: 0.3px;
  }
}
@media screen and (min-width: 1100px) {
  .media--recruit .media__contents-info {
    letter-spacing: 1.2px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 430px) {
  .media--recruit .media__contents-info {
    letter-spacing: 0.2790697674vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 768px) {
  .media--recruit .media__contents-info {
    letter-spacing: 0.1090909091vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 1100px) {
  .media--recruit .media__contents-info {
    letter-spacing: 1.2px;
  }
}

/* 投稿を4枚ごとに背景色を変える */
.media--recruit:nth-child(4n+1) .media__contents {
  background-color: #C03605;
  /* 背景色を指定 */
}

.media--recruit:nth-child(4n+2) .media__contents {
  background-color: #364961;
  /* 背景色を指定 */
}

.media--recruit:nth-child(4n+3) .media__contents {
  background-color: #3D6155;
  /* 背景色を指定 */
}

.media--recruit:nth-child(4n+4) .media__contents {
  background-color: #A07C28;
  /* 背景色を指定 */
}

.interview-contents {
  font-family: "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ";
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 4.1860465116vw;
  margin-bottom: 7.9069767442vw;
}
@media (max-width: 430px) {
  .interview-contents {
    padding-right: 2.7906976744vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents {
    padding-right: 1.0909090909vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents {
    padding-right: 12px;
  }
}
@media (max-width: 430px) {
  .interview-contents {
    padding-left: 2.7906976744vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents {
    padding-left: 1.0909090909vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents {
    padding-left: 12px;
  }
}
@media screen and (min-width: 430px) {
  .interview-contents {
    padding-top: 4.1860465116vw;
  }
}
@media screen and (min-width: 430px) {
  .interview-contents {
    margin-bottom: 7.9069767442vw;
  }
}
@media screen and (min-width: 500px) {
  .interview-contents {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media screen and (min-width: 500px) and (max-width: 430px) {
  .interview-contents {
    padding-right: 5.8139534884vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 768px) {
  .interview-contents {
    padding-right: 2.2727272727vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 1100px) {
  .interview-contents {
    padding-right: 25px;
  }
}
@media screen and (min-width: 500px) and (max-width: 430px) {
  .interview-contents {
    padding-left: 5.8139534884vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 768px) {
  .interview-contents {
    padding-left: 2.2727272727vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 1100px) {
  .interview-contents {
    padding-left: 25px;
  }
}
@media screen and (min-width: 600px) {
  .interview-contents {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (min-width: 600px) and (max-width: 430px) {
  .interview-contents {
    padding-right: 11.6279069767vw;
  }
}
@media screen and (min-width: 600px) and (min-width: 768px) {
  .interview-contents {
    padding-right: 4.5454545455vw;
  }
}
@media screen and (min-width: 600px) and (min-width: 1100px) {
  .interview-contents {
    padding-right: 50px;
  }
}
@media screen and (min-width: 600px) and (max-width: 430px) {
  .interview-contents {
    padding-left: 11.6279069767vw;
  }
}
@media screen and (min-width: 600px) and (min-width: 768px) {
  .interview-contents {
    padding-left: 4.5454545455vw;
  }
}
@media screen and (min-width: 600px) and (min-width: 1100px) {
  .interview-contents {
    padding-left: 50px;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents {
    padding-right: 80px;
    padding-left: 80px;
    padding-top: 58px;
    margin-bottom: 44px;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .interview-contents {
    padding-right: 18.6046511628vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .interview-contents {
    padding-right: 7.2727272727vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .interview-contents {
    padding-right: 80px;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .interview-contents {
    padding-left: 18.6046511628vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .interview-contents {
    padding-left: 7.2727272727vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .interview-contents {
    padding-left: 80px;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .interview-contents {
    padding-top: 13.488372093vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .interview-contents {
    padding-top: 5.2727272727vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .interview-contents {
    padding-top: 58px;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .interview-contents {
    margin-bottom: 10.2325581395vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .interview-contents {
    margin-bottom: 4vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .interview-contents {
    margin-bottom: 44px;
  }
}

.interview-contents__inner {
  max-width: 370px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 430px) {
  .interview-contents__inner {
    max-width: 86.0465116279vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__inner {
    max-width: 33.6363636364vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents__inner {
    max-width: 370px;
  }
}
@media screen and (min-width: 480px) {
  .interview-contents__inner {
    max-width: 900px;
  }
}

.interview-contents__text {
  text-align: left;
  margin-bottom: 50px;
}
@media (max-width: 430px) {
  .interview-contents__text {
    margin-bottom: 11.6279069767vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__text {
    margin-bottom: 4.5454545455vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents__text {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__text {
    margin-bottom: 54px;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .interview-contents__text {
    margin-bottom: 12.5581395349vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .interview-contents__text {
    margin-bottom: 4.9090909091vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .interview-contents__text {
    margin-bottom: 54px;
  }
}

.interview-contents__paragraph-group {
  margin-bottom: 43px;
}
@media (max-width: 430px) {
  .interview-contents__paragraph-group {
    margin-bottom: 10vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__paragraph-group {
    margin-bottom: 3.9090909091vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents__paragraph-group {
    margin-bottom: 43px;
  }
}

.interview-contents__paragraph-group:last-child {
  margin-bottom: 0;
}

.interview-contents__paragraph-title {
  border-bottom: 2px solid;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.76px;
  padding-bottom: 17px;
  margin-bottom: 18px;
  line-height: 1.58;
}
@media screen and (min-width: 768px) {
  .interview-contents__paragraph-title {
    font-size: max(1rem, 14px);
  }
}
@media (max-width: 430px) {
  .interview-contents__paragraph-title {
    letter-spacing: 0.176744186vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__paragraph-title {
    letter-spacing: 0.0690909091vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents__paragraph-title {
    letter-spacing: 0.76px;
  }
}
@media (max-width: 430px) {
  .interview-contents__paragraph-title {
    padding-bottom: 3.9534883721vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__paragraph-title {
    padding-bottom: 1.5454545455vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents__paragraph-title {
    padding-bottom: 17px;
  }
}
@media (max-width: 430px) {
  .interview-contents__paragraph-title {
    margin-bottom: 4.1860465116vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__paragraph-title {
    margin-bottom: 1.6363636364vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents__paragraph-title {
    margin-bottom: 18px;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__paragraph-title {
    font-size: 20px;
    letter-spacing: 1.05px;
    margin-bottom: 16px;
    line-height: 1.8em;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .interview-contents__paragraph-title {
    font-size: 4.6511627907vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .interview-contents__paragraph-title {
    font-size: max(1.8181818182vw, 17.5px);
    font-size: max(1.8181818182vw, 16.3px);
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .interview-contents__paragraph-title {
    font-size: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .interview-contents__paragraph-title {
    letter-spacing: 0.2441860465vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .interview-contents__paragraph-title {
    letter-spacing: 0.0954545455vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .interview-contents__paragraph-title {
    letter-spacing: 1.05px;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .interview-contents__paragraph-title {
    margin-bottom: 3.7209302326vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .interview-contents__paragraph-title {
    margin-bottom: 1.4545454545vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .interview-contents__paragraph-title {
    margin-bottom: 16px;
  }
}

.interview-contents__paragraph {
  font-weight: 300;
  font-weight: 400;
  font-weight: 500;
  white-space: pre-line;
  letter-spacing: 0.86px;
  line-height: 1.5;
  font-size: 16px;
}
@media (max-width: 430px) {
  .interview-contents__paragraph {
    letter-spacing: 0.2vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__paragraph {
    letter-spacing: 0.0781818182vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents__paragraph {
    letter-spacing: 0.86px;
  }
}
@media (max-width: 430px) {
  .interview-contents__paragraph {
    font-size: 3.7209302326vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__paragraph {
    font-size: max(1.4545454545vw, 14px);
  }
}
@media (min-width: 1100px) {
  .interview-contents__paragraph {
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__paragraph {
    letter-spacing: 0.85px;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .interview-contents__paragraph {
    letter-spacing: 0.1976744186vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .interview-contents__paragraph {
    letter-spacing: 0.0772727273vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .interview-contents__paragraph {
    letter-spacing: 0.85px;
  }
}

.interview-contents__btn {
  text-align: center;
}

.interview-contents__btn-link {
  display: inline-flex;
  border-radius: 3px;
  border: double 3px #FFFFFF;
  background-color: #333333;
  max-width: 240px;
  width: 100%;
  padding-top: 13px;
  padding-bottom: 13px;
  justify-content: center;
  align-items: center;
}
@media (max-width: 430px) {
  .interview-contents__btn-link {
    border-radius: 0.6976744186vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__btn-link {
    border-radius: 0.2727272727vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents__btn-link {
    border-radius: 3px;
  }
}
@media (max-width: 430px) {
  .interview-contents__btn-link {
    max-width: 55.8139534884vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__btn-link {
    max-width: 21.8181818182vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents__btn-link {
    max-width: 240px;
  }
}
@media (max-width: 430px) {
  .interview-contents__btn-link {
    padding-top: 3.023255814vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__btn-link {
    padding-top: 1.1818181818vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents__btn-link {
    padding-top: 13px;
  }
}
@media (max-width: 430px) {
  .interview-contents__btn-link {
    padding-bottom: 3.023255814vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__btn-link {
    padding-bottom: 1.1818181818vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents__btn-link {
    padding-bottom: 13px;
  }
}

.interview-contents__btn-img {
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 3px;
}
@media (max-width: 430px) {
  .interview-contents__btn-img {
    margin-right: 1.6279069767vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__btn-img {
    margin-right: 0.6363636364vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents__btn-img {
    margin-right: 7px;
  }
}
@media (max-width: 430px) {
  .interview-contents__btn-img {
    margin-bottom: 0.6976744186vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-contents__btn-img {
    margin-bottom: 0.2727272727vw;
  }
}
@media (min-width: 1100px) {
  .interview-contents__btn-img {
    margin-bottom: 3px;
  }
}

.page_title.interview-header {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../img/recruit/main_voice.jpg");
  background-size: cover;
  background-blend-mode: darken;
}

.interview {
  font-family: "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ";
  background: url("../img/common/bg_mode_wood.jpg");
  background-repeat: repeat;
  padding-top: 45px;
  padding-bottom: 73px;
  padding-right: 12px;
  padding-left: 12px;
}
@media (max-width: 430px) {
  .interview {
    padding-top: 10.4651162791vw;
  }
}
@media screen and (min-width: 768px) {
  .interview {
    padding-top: 4.0909090909vw;
  }
}
@media (min-width: 1100px) {
  .interview {
    padding-top: 45px;
  }
}
@media (max-width: 430px) {
  .interview {
    padding-bottom: 16.976744186vw;
  }
}
@media screen and (min-width: 768px) {
  .interview {
    padding-bottom: 6.6363636364vw;
  }
}
@media (min-width: 1100px) {
  .interview {
    padding-bottom: 73px;
  }
}
@media (max-width: 430px) {
  .interview {
    padding-right: 2.7906976744vw;
  }
}
@media screen and (min-width: 768px) {
  .interview {
    padding-right: 1.0909090909vw;
  }
}
@media (min-width: 1100px) {
  .interview {
    padding-right: 12px;
  }
}
@media (max-width: 430px) {
  .interview {
    padding-left: 2.7906976744vw;
  }
}
@media screen and (min-width: 768px) {
  .interview {
    padding-left: 1.0909090909vw;
  }
}
@media (min-width: 1100px) {
  .interview {
    padding-left: 12px;
  }
}
@media screen and (min-width: 500px) {
  .interview {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media screen and (min-width: 500px) and (max-width: 430px) {
  .interview {
    padding-right: 5.8139534884vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 768px) {
  .interview {
    padding-right: 2.2727272727vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 1100px) {
  .interview {
    padding-right: 25px;
  }
}
@media screen and (min-width: 500px) and (max-width: 430px) {
  .interview {
    padding-left: 5.8139534884vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 768px) {
  .interview {
    padding-left: 2.2727272727vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 1100px) {
  .interview {
    padding-left: 25px;
  }
}
@media screen and (min-width: 768px) {
  .interview {
    padding-top: 7.03125vw;
  }
}
@media screen and (min-width: 1100px) {
  .interview {
    padding-top: 97px;
    padding-bottom: 84px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 430px) {
  .interview {
    padding-top: 22.5581395349vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 768px) {
  .interview {
    padding-top: 8.8181818182vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 1100px) {
  .interview {
    padding-top: 97px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 430px) {
  .interview {
    padding-bottom: 19.5348837209vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 768px) {
  .interview {
    padding-bottom: 7.6363636364vw;
  }
}
@media screen and (min-width: 1100px) and (min-width: 1100px) {
  .interview {
    padding-bottom: 84px;
  }
}

.interview-items {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 3.125rem;
  max-width: 370px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 430px) {
  .interview-items {
    max-width: 86.0465116279vw;
  }
}
@media screen and (min-width: 768px) {
  .interview-items {
    max-width: 33.6363636364vw;
  }
}
@media (min-width: 1100px) {
  .interview-items {
    max-width: 370px;
  }
}
@media screen and (min-width: 500px) {
  .interview-items {
    max-width: 440px;
    gap: 30px;
  }
}
@media screen and (min-width: 500px) and (max-width: 430px) {
  .interview-items {
    gap: 6.976744186vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 768px) {
  .interview-items {
    gap: 2.7272727273vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 1100px) {
  .interview-items {
    gap: 30px;
  }
}
@media screen and (min-width: 1000px) {
  .interview-items {
    max-width: 1030px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1000px) and (max-width: 430px) {
  .interview-items {
    max-width: 239.5348837209vw;
  }
}
@media screen and (min-width: 1000px) and (min-width: 768px) {
  .interview-items {
    max-width: 93.6363636364vw;
  }
}
@media screen and (min-width: 1000px) and (min-width: 1100px) {
  .interview-items {
    max-width: 1030px;
  }
}

.interview .interview__title {
  font-size: 20px;
  font-weight: 600;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  letter-spacing: 0;
}
@media (max-width: 430px) {
  .interview .interview__title {
    font-size: 4.6511627907vw;
  }
}
@media screen and (min-width: 768px) {
  .interview .interview__title {
    font-size: max(1.8181818182vw, 17.5px);
    font-size: max(1.8181818182vw, 16.3px);
  }
}
@media (min-width: 1100px) {
  .interview .interview__title {
    font-size: 20px;
  }
}
@media (max-width: 430px) {
  .interview .interview__title {
    margin-bottom: 6.976744186vw;
  }
}
@media screen and (min-width: 768px) {
  .interview .interview__title {
    margin-bottom: 2.7272727273vw;
  }
}
@media (min-width: 1100px) {
  .interview .interview__title {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 500px) {
  .interview .interview__title {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 500px) and (max-width: 430px) {
  .interview .interview__title {
    margin-bottom: 9.3023255814vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 768px) {
  .interview .interview__title {
    margin-bottom: 3.6363636364vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 1100px) {
  .interview .interview__title {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 768px) {
  .interview .interview__title {
    margin-bottom: 6.9010416667vw;
    font-size: 20px;
  }
}
@media screen and (min-width: 1000px) {
  .interview .interview__title {
    font-size: 24px;
    margin-bottom: 84px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 430px) {
  .interview .interview__title {
    font-size: 5.5813953488vw;
  }
}
@media screen and (min-width: 1000px) and (min-width: 768px) {
  .interview .interview__title {
    font-size: max(2.1818181818vw, 21px);
    font-size: max(2.1818181818vw, 18.12px);
  }
}
@media screen and (min-width: 1000px) and (min-width: 1100px) {
  .interview .interview__title {
    font-size: 24px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 430px) {
  .interview .interview__title {
    margin-bottom: 19.5348837209vw;
  }
}
@media screen and (min-width: 1000px) and (min-width: 768px) {
  .interview .interview__title {
    margin-bottom: 7.6363636364vw;
  }
}
@media screen and (min-width: 1000px) and (min-width: 1100px) {
  .interview .interview__title {
    margin-bottom: 84px;
  }
}
@media screen and (min-width: 1100px) {
  .interview .interview__title {
    font-size: 28px;
  }
}

.interview__title::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 123px;
  aspect-ratio: 214/132;
  background-image: url("../img/common/bg_maru.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 430px) {
  .interview__title::before {
    width: 28.6046511628vw;
  }
}
@media screen and (min-width: 768px) {
  .interview__title::before {
    width: 11.1818181818vw;
  }
}
@media (min-width: 1100px) {
  .interview__title::before {
    width: 123px;
  }
}
@media screen and (min-width: 768px) {
  .interview__title::before {
    width: 123px;
  }
}
@media screen and (min-width: 1000px) {
  .interview__title::before {
    width: 214px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 430px) {
  .interview__title::before {
    width: 49.7674418605vw;
  }
}
@media screen and (min-width: 1000px) and (min-width: 768px) {
  .interview__title::before {
    width: 19.4545454545vw;
  }
}
@media screen and (min-width: 1000px) and (min-width: 1100px) {
  .interview__title::before {
    width: 214px;
  }
}

.top-interview {
  font-family: "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ";
  padding-top: 20px;
  margin-bottom: 75px;
}
@media (max-width: 430px) {
  .top-interview {
    padding-top: 4.6511627907vw;
  }
}
@media screen and (min-width: 768px) {
  .top-interview {
    padding-top: 1.8181818182vw;
  }
}
@media (min-width: 1100px) {
  .top-interview {
    padding-top: 20px;
  }
}
@media (max-width: 430px) {
  .top-interview {
    margin-bottom: 17.4418604651vw;
  }
}
@media screen and (min-width: 768px) {
  .top-interview {
    margin-bottom: 6.8181818182vw;
  }
}
@media (min-width: 1100px) {
  .top-interview {
    margin-bottom: 75px;
  }
}
@media screen and (min-width: 500px) {
  .top-interview {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 500px) and (max-width: 430px) {
  .top-interview {
    margin-bottom: 9.3023255814vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 768px) {
  .top-interview {
    margin-bottom: 3.6363636364vw;
  }
}
@media screen and (min-width: 500px) and (min-width: 1100px) {
  .top-interview {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 768px) {
  .top-interview {
    padding-top: 25px;
  }
}
@media screen and (min-width: 768px) and (max-width: 430px) {
  .top-interview {
    padding-top: 5.8139534884vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .top-interview {
    padding-top: 2.2727272727vw;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .top-interview {
    padding-top: 25px;
  }
}

.top-interview .interview-items {
  grid-template-columns: repeat(1, 1fr);
}
@media screen and (min-width: 1000px) {
  .top-interview .interview-items {
    max-width: 500px;
  }
}

.top-interview .media__contents-info {
  letter-spacing: 0.01em;
}
@media screen and (min-width: 430px) {
  .top-interview .media__contents-info {
    letter-spacing: 0.05em;
  }
}
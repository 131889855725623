@use "global" as *;

.interview-contents {
  font-family: "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ";
  @include vw(12, padding-right);
  @include vw(12, padding-left);
  @include maintain_value_to_sp(18, padding-top);
  @include maintain_value_to_sp(34, margin-bottom);

  @include mq(500) {
    @include vw(25, padding-right);
    @include vw(25, padding-left);
  }

  @include mq(600) {
    @include vw(50, padding-right);
    @include vw(50, padding-left);
  }

  @include mq("md") {
    @include vw(80, padding-right);
    @include vw(80, padding-left);
    @include vw(58, padding-top);
    @include vw(44, margin-bottom);
  }
}

.interview-contents__inner {
  @include vw(370, max-width);
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @include mq(480) {
    // @include vw(900, max-width);
    max-width: 900px;
  }
}

.interview-contents__text {
  text-align: left;
  @include vw(50, margin-bottom);

  @include mq("md") {
    @include vw(54, margin-bottom);
  }
}

.interview-contents__paragraph-group {
  @include vw(43, margin-bottom);
}

.interview-contents__paragraph-group:last-child {
  margin-bottom: 0;
}

.interview-contents__paragraph-title {
  border-bottom: 2px solid;
  font-weight: 600;
  @include fz(16);
  @include vw(0.76, letter-spacing);
  @include vw(17, padding-bottom);
  @include vw(18, margin-bottom);
  line-height: 1.58;

  @include mq("md") {
    @include vw(20, font-size);
    @include vw(1.05, letter-spacing);
    @include vw(16, margin-bottom);
    line-height: 1.8em;
  }
}

.interview-contents__paragraph {
  font-weight: 300;
  font-weight: 400;
  font-weight: 500;
  white-space: pre-line;
  @include vw(0.86, letter-spacing);
  line-height: 1.5;
  @include vw(16, font-size);

  @include mq("md") {
    @include vw(0.85, letter-spacing);
  }
}

.interview-contents__btn {
  text-align: center;
}

.interview-contents__btn-link {
  display: inline-flex;
  @include vw(3, border-radius);
  border: double 3px #FFFFFF;
  background-color: #333333;
  @include vw(240, max-width);
  width: 100%;
  @include vw(13, padding-top);
  @include vw(13, padding-bottom);
  justify-content: center;
  align-items: center;
}

.interview-contents__btn-img {
  display: inline-block;
  @include vw(7, margin-right);
  @include vw(3, margin-bottom);
}

.page_title.interview-header {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../img/recruit/main_voice.jpg');
  background-size: cover;
  background-blend-mode: darken;
}